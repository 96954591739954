* {
  box-sizing: border-box;
}

body {
  background-color: whitesmoke;
  margin: 0 auto;
  padding: 0;
  overflow: hidden;
}

// OSM Tiles Style
.leaflet-tile-loaded {
  filter: grayscale(80%) brightness(106%) saturate(50%);
}
// Leaflet Rectangle
.rectangleClass {
  cursor: default !important;
}
